<template>
  <div class="reference-list">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.reference.list.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <filter-reference @handleAlert="handleAlert" @setFilterData="setFilterData"/>
        </v-card>
         <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <v-data-table
            :headers="headers"
            :items="referenceList"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
            class="elevation-2 mt-4"
            @update:options="updateOpt"
          >
          </v-data-table>
          <div class="d-flex justify-end pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
              @input="changeListPage"
            ></v-pagination>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import FilterReference from '@/apps/dashboard/components/filters/filterReference'
import {
  API_DASH_GET_STUDENT_LIST
} from '@/constants/apis'
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'
  export default {
    name: 'ReferenceList',
    components: {
      FilterReference,
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        headers: [
          {
            text: 'Nama Mahasiswa',
            align: 'start',
            value: 'name',
            class: 'black--text subtitle-2'
          },
          { text: 'E-mail', value: 'email', class: 'black--text subtitle-2' },
          { text: 'Nomor WA', value: 'phone', class: 'black--text subtitle-2' },
          { text: 'Peran', value: 'role', class: 'black--text subtitle-2' },
        ],
        referenceList: [],
        totalList: 0,
        loading: true,
        options: {},
        limit: 10,
        page: 1,
        pageCount: 1,
        filterData: {
          name: '',
          role: 'semua',
        },
      }
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      updateOpt (e) {
        if (e.itemsPerPage !== this.limit) {
          this.limit = e.itemsPerPage
          this.setFilterData(this.filterData)
        }
      },
      setFilterData (params) {
        this.filterData.name = params.name
        this.filterData.role = params.role === 'semua' ? '' : params.role
        this.page = 1
        this.getDataFromApi()
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      },
      getDataFromApi () {
        this.loading = true
        const params = {}
        params.page = this.page
        params.limit = this.limit
        if (this.filterData.name) params.name = this.filterData.name
        if (this.filterData.role && this.filterData.role !== 'semua') params.role = this.filterData.role
        request(API_DASH_GET_STUDENT_LIST, params).then(res => {
          if (res.success) {
            this.referenceList = res.list
            this.pageCount = res.pagination.total_page
            this.loading = false
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      changeListPage () {
        this.getDataFromApi()
      }
    },
  }
</script>

<style lang="scss" scoped>
.reference-list {

}
</style>