<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <v-form id="filter-reference-list" @submit.prevent="checkReferenceList">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nama Mahasiswa"
              rules=""
            >
              <p class="title-14 mb-2">Nama Mahasiswa</p>
              <v-text-field
                placeholder="Nama Mahasiswa"
                v-model="name"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
          </v-col>
           <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <div>
              <p class="title-14 mb-2">Peran</p>
              <v-select
                v-model="role"
                :items="roles"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="filter-reference-list"
            large
            :disabled="isDisabled || invalid"
          >
            TEMUKAN
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  // Libs
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { get } from 'dot-prop'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  
  // Constants
  import {
    // ...
  } from '@/constants/apis'

  export default {
    name: 'FilterReference',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data () {
      return {
        name: '',
        role: 'semua'
      }
    },
    computed: {
      isDisabled () {
        return this.isError
      },
      roles () {
        return [
          'semua',
          'mahasiswa',
          'peminat'
        ]
      },
    },
    methods: {
      async checkReferenceList () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const params = {
          name: this.name,
          role: this.role,
        }
        this.$emit('setFilterData', params)
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
</style>
